import { useEffect } from 'react'
import { debug } from 'lib/logging'

const stack: (() => void)[] = []

const handleEscape = (event: KeyboardEvent) => {
  debug(`escape`, `handleEscape`, event, `stack`, stack.length)
  if (event.key === `Escape`) {
    stack[stack.length - 1]?.()
    event.preventDefault()
    event.stopImmediatePropagation()
  }
}

export function useEscapeListener(listener: () => void, enabled = true) {
  debug(`escape`, `useEscapeListener`, listener, enabled, stack.length)

  // const [_enabled, _setEnabled] = useState(enabled)

  useEffect(() => {
    debug(`escape`, `useEffect`, listener, stack.indexOf(listener), enabled)
    if (enabled) {
      stack.push(listener)
    } else {
      if (stack.indexOf(listener) >= 0) stack.splice(stack.indexOf(listener), 1)
    }
    debug(`escape`, `useEffect`, listener, enabled, stack.length)

    return () => {
      if (stack.indexOf(listener) >= 0) stack.splice(stack.indexOf(listener), 1)
      debug(`escape`, `useEffect exit`, listener, stack.length)
    }
  }, [enabled])

  useEffect(() => {
    window.addEventListener(`keydown`, handleEscape, false)
    return () => {
      window.removeEventListener(`keydown`, handleEscape, false)
    }
  }, [])

  return
}
