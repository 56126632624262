import * as React from 'react'
import ConfirmationAlert, { ConfirmationOptions } from 'app/components/ConfirmationAlert'
import { useEscapeListener } from 'app/hooks/useEscapeListener'

const ConfirmationServiceContext = React.createContext<[(options: ConfirmationOptions) => Promise<void>, boolean]>([
  Promise.reject,
  false,
])

export const useConfirmation = () => React.useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null)
  const [open, setOpen] = React.useState<boolean>(false)
  useEscapeListener(() => handleClose(), open)

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    setOpen(true)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setOpen(false)
  }

  const handleExited = () => {
    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider value={[openConfirmation, open]} children={children} />

      <ConfirmationAlert
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onExited={handleExited}
        variant={`danger`}
        title={`Confirm action`}
        description={`Are you sure you want to proceed?`}
        {...confirmationState}
      />
    </>
  )
}
